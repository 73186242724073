<template>
  <v-app>
    <v-diglog-confirm :show="isConfirm" :close="login" />
    <v-main>
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="7">
            <v-card v-if="!progressing" flat class="reset-block-container">
              <v-card-title t-data="header-logo">
                <img class="logo" :src="hospitalLogo" width="360" alt />
              </v-card-title>
              <v-card-subtitle t-data="subtitle">
                <p class="form-title">
                  Please reset your password to access Princ Health Portal.
                </p>
              </v-card-subtitle>
              <v-card-text>
                <v-form lazy-validation>
                  <label for="password" t-data="info-input">
                    New Password
                    <div class="d-flex justify-space-between align-start">
                      <v-text-field
                        id="password"
                        v-model="password"
                        :error-messages="pswErrors"
                        :hide-details="pswErrors.length <= 0"
                        type="password"
                        placeholder="Enter a new password"
                        change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        @input="clearResponseErrors"
                        outlined
                      />
                      <v-tooltip
                        v-model="show"
                        class="px-2"
                        color="rgba(0, 0, 0, 0.85)"
                        max-width="330"
                        top
                      >
                        <template v-slot:activator="{on, attrs}">
                            <v-icon color="secondaryTextColor" size="24" v-bind="attrs" v-on="on" class="px-2 mt-4">
                              mdi-information-slab-circle
                            </v-icon>
                        </template>
                        <span
                          >Enter a password with level of 4 or higher, at least
                          12 characters long but 14 or more is better. A
                          combination of uppercase letters, low case letters,
                          numbers, and symbols.</span
                        >
                      </v-tooltip>
                    </div>
                    <password
                      class="custom-password adjust-center"
                      v-model="password"
                      :strength-meter-only="true"
                      @score="getScore"
                      :style="{maxWidth: 'unset'}"
                    />
                  </label>
                  <div class="mt-12">
                    <label for="confirmPassword" t-data="info-input">
                      Confirm Password
                      <v-text-field
                        id="confirmPassword"
                        class="adjust-center"
                        v-model="confirmPassword"
                        :error-messages="pswCfErrors"
                        type="password"
                        placeholder="Confirm your new password"
                        change="$v.confirmPassword.$touch()"
                        @blur="$v.confirmPassword.$touch()"
                        @input="clearResponseErrors"
                        outlined
                      />
                    </label>
                  </div>
                </v-form>
                <div v-if="isError" class="text-center" t-data="error-msg">
                  <span class="red--text">{{ msg }}</span>
                </div>
              </v-card-text>
              <v-card-actions align="center" justify="center" class="adjust-center-action">
                <v-btn
                  v-if="isError"
                  @click="login"
                  large
                  color="primary"
                  class="btn-confirm"
                  t-data="back-btn"
                  >Back to Login</v-btn
                >
                <v-btn
                  v-if="!isError"
                  @click="submit"
                  large
                  color="primary"
                  class="btn-confirm"
                  t-data="confirm-btn"
                  >Confirm</v-btn
                >
              </v-card-actions>
            </v-card>
            <!--- error -->

            <!-- Spinner -->
            <div v-if="progressing" class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, sameAs} from 'vuelidate/lib/validators'
import Confirm from '../../components/AlertResetPassword'
import {imageHospitalLogo, mobileAppName} from '@/constants/constants'
import Password from 'vue-password-strength-meter'
import {isValidPasswordChar} from '@/helper/helper'

export default {
  components: {
    'v-diglog-confirm': Confirm,
    Password
  },
  mixins: [validationMixin],

  validations: {
    password: {
      required,
      isValidPasswordChar
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  },
  created() {
    this.isResetToken = !!this.$route.params.token
  },
  computed: {
    appName() {
      return mobileAppName
    },
    hospitalLogo() {
      return imageHospitalLogo
    },
    pswCfErrors() {
      const errors = [...this.responseErrors]
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.required &&
        errors.push('You must enter the password confirmation.')
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push('Confirmed password does not match. Please retry again.')
      return errors
    },
    pswErrors() {
      const errors = [...this.responseErrors]
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('You must enter the password.')
      !this.$v.password.isValidPasswordChar &&
        errors.push(
          'Please enter a password is EN character, Number and symbol.'
        )
      this.passwordScore < 3 &&
        errors.push('Enter a password with a security level of 4 or higher.')
      return errors
    }
  },

  methods: {
    ...mapActions('auth', ['reset', 'validateToken']),
    login() {
      this.$router.push({name: 'login'})
    },
    getScore(score) {
      this.passwordScore = score
    },
    clearResponseErrors() {
      this.responseErrors = []
    },
    async submit() {
      // Validate form.
      this.$v.$touch()
      if (this.$v.$invalid) return

      // Show waiter inprogress.
      this.progressing = true
      if (this.isResetToken) {
        try {
          const success = await this.validateToken({
            token: this.$route.params.token,
            new_password: this.password
          })
          if (success) {
            return (this.isConfirm = true)
          }
        } catch (e) {
          this.responseErrors.push(e.response.data.message)
        }
      } else {
        try {
          const success = await this.reset(this.password)
          if (success) {
            return this.$router.push({name: 'login'})
          }
        } catch (e) {
          this.responseErrors.push(e.response.data.message)
          /* Do nothing for 401 */
        }
      }

      // Show error message for 5 secs.
      this.fail = true
      setTimeout(() => {
        this.fail = false
      }, 5000)
      this.progressing = false
    }
  },

  data() {
    return {
      isConfirm: false,
      isError: false,
      msg: '',
      fail: false,
      progressing: false,
      password: '',
      confirmPassword: '',
      isResetToken: false,
      passwordScore: 0,
      responseErrors: [],
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  & > label {
    font-size: 15px;
  }
}
.logo {
  margin-left: -20px;
}
.form-title {
  font-size: 15px;
  padding-top: 20px;
}
.btn-confirm {
  width: 40%;
  margin: 0 30%;
}

</style>

<style lang="scss">
$info-icon-width: 40px;

.reset-block-container {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-right: 10px !important;
  padding-left: calc(#{$info-icon-width} + 10px) !important;
}

.adjust-center {
  padding-right: $info-icon-width !important;
}

.adjust-center-action {
  padding-right: calc(#{$info-icon-width} + 8px) !important;
}
</style>

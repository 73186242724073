<template>
  <v-dialog v-model="show" persistent max-width="600">
    <v-card>
      <v-card-title t-data="headline">
        <span class="blue--text"></span>
      </v-card-title>
     <v-card-text t-data="card-text">
      <div class="text--primary">
        Your password has been changed successfully.<br>
        You are now able to log in with your new password.
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
         <v-btn t-data="action-btn" small color="primary" @click="close">Go to Login page</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertResetPassword',
  props: {
    show: {type: Boolean},
    close: {type: Function}
  },
  methods: {
    closePopup() {
      this.close()
    }
  }
}
</script>


<style scoped>
</style>

